/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-key */
import { Dialog, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import "./modal.scss";

const ConfirmModal = ({ open, handleCloseDialog, header, children }) => {
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
      className="confirm-modal"
    >
      <div className="confirm-modal__container">
        <div className="confirm-modal__header-container">
          <div className="confirm-modal__header">{header}</div>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            className="confirm-modal__close-button"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="confirm-modal__body-container">{children}</div>
      </div>
    </Dialog>
  );
};

export default ConfirmModal;

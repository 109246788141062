import { withStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { ThreeDot } from "../../../../images";

import ConfirmDeleteModal from "../../../../components/ConfirmDeleteModal";
import { removeUserFromScenario } from "../../../../api/scenario";
import { useQueryInvalidationWithNotification } from "../../../../hooks";

const StyledMenuItem = withStyles({
  root: {
    fontSize: 14,
    fontFamily: "ProximaNovaRegular",
    justifyContent: "center",
    textAlign: "center",
  },
})(MenuItem);

const EditApplicantDialog = ({ scenarioId, connection }) => {
  const [openMenu, setOpenMenu] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(null);

  const handleDialogToggle = (value) => {
    setOpenMenu(value);
  };
  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const invalidateQueries = useQueryInvalidationWithNotification();

  const invalidateScenario = () => {
    invalidateQueries("getConnectionByScenario");
    invalidateQueries("scenarioList");
    invalidateQueries(["scenarioProductList", scenarioId]);
    invalidateQueries(["maxLoanHistoryChartData", scenarioId]);
    invalidateQueries(["maxBuyingPowerHistoryChartData", scenarioId]);
    invalidateQueries(["buyingPowerHistoryByScenario", scenarioId]);
  };

  const { enqueueSnackbar } = useSnackbar();

  const removeJointScenarioMember = useMutation(
    (payload) => removeUserFromScenario(payload.scenarioId, payload.userId),
    {
      onSuccess: (result) => {
        invalidateScenario(result.scenarioId || connection.scenarioId);
      },
    }
  );

  const handleRemoveMember = () => {
    removeJointScenarioMember.mutate(
      { scenarioId, userId: connection.memberId },
      {
        onSuccess: (result) => {
          enqueueSnackbar("Delete applicant successfully", {
            variant: "success",
          });
          setOpenConfirmModal(false);
          invalidateScenario(result.scenarioId || connection.scenarioId);
        },
      }
    );
  };

  return (
    <>
      <div
        onClick={handleClick}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        className="three-dots-icon"
      >
        <img src={ThreeDot} alt="three-dot" />
      </div>
      <ConfirmDeleteModal
        open={openConfirmModal}
        handleCloseDialog={() => setOpenConfirmModal(false)}
        handleConfirm={() => {
          handleRemoveMember();
        }}
        title="Are you sure to remove this applicant?"
        header="Remove Applicant"
        label="Remove"
        loading={removeJointScenarioMember.isLoading}
      />
      <Menu
        id="long-menu"
        anchorEl={openMenu}
        keepMounted
        open={Boolean(openMenu)}
        onClose={() => handleDialogToggle(false)}
        PaperProps={{
          style: {
            fontFamily: "ProximaNovaRegular",
            fontSize: 14,
            width: "20ch",
          },
        }}
      >
        <StyledMenuItem
          className="remove-property"
          onClick={() => setOpenConfirmModal(true)}
        >
          <div
            className="w-100"
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
          >
            <div>Delete Applicant</div>
          </div>
        </StyledMenuItem>
      </Menu>
    </>
  );
};

export default EditApplicantDialog;

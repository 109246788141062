import React from "react";
import PropTypes from "prop-types";
import { JointScenarioConnectionStatus } from "../../../../types/join-account";
import EditApplicantDialog from "./edit-applicant-dialog";

const LoanApplicantInformation = ({
  name = "",
  phone = "",
  email = "",
  status,
  connection,
  scenarioId,
  isOwner,
}) => {
  return (
    <div className="applicant-container" style={{ position: "relative" }}>
      {!isOwner && (
        <EditApplicantDialog scenarioId={scenarioId} connection={connection} />
      )}
      <div className="applicant-container-name">
        {name}{" "}
        {status === JointScenarioConnectionStatus.WAITING && (
          <span>(Waiting)</span>
        )}
      </div>
      <p>
        <span className="font-weight-bold">{phone}</span> contact number
      </p>
      <p>
        <span className="font-weight-bold">{email}</span> contact email
      </p>
    </div>
  );
};

LoanApplicantInformation.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
};

export default LoanApplicantInformation;

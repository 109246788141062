/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-key */
import React from "react";

import ConfirmModal from "./modal";
import CustomButton, { CustomButtonOutLine } from "../custom-button";
import "./delete-modal.scss";

const ConfirmDeleteModal = ({
  open,
  handleCloseDialog,
  handleConfirm,
  loading,
  title = "Are you sure you want to delete this?",
  header = "Confirm to delete?",
  label = "Delete",
}) => {
  const closeModal = () => {
    handleCloseDialog();
  };
  return (
    <ConfirmModal open={open} handleCloseDialog={closeModal} header={header}>
      <div className="delete-modal__content">
        <div>{title}</div>
      </div>
      <div className="delete-modal__button-container">
        <CustomButtonOutLine size="small" label="Cancel" onClick={closeModal} />
        <CustomButton
          size="small"
          isLoading={loading}
          label={label}
          onClick={handleConfirm}
          disabled={loading}
        />
      </div>
    </ConfirmModal>
  );
};

export default ConfirmDeleteModal;
